<template>
  <div id="login">
    <div class="container-top"></div>
    <div class="container-login">
      <div class="login">
        <img
          src="@/assets/logo.png"
          alt="Juancho Te Ayuda"
          class="image is-150x150"
        />
        <h1 class="is-size-3 has-text-centered has-text-weight-bold mb-3">
          Iniciar Sesión
        </h1>
        <form autocomplete="off" @submit.prevent="submitLogin">
          <div class="field">
            <label class="label">Usuario</label>
            <div class="control has-icons-left has-icons-right">
              <input
                class="input"
                type="text"
                id="user"
                :class="{ 'is-danger': $v.username.$error }"
                v-model.trim="$v.username.$model"
                v-focus
              />
              <span class="icon is-small is-left">
                <font-awesome-icon icon="user"></font-awesome-icon>
              </span>
            </div>
          </div>
          <div class="field">
            <label class="label">Contraseña</label>
            <div class="control has-icons-left has-icons-right">
              <input
                class="input"
                type="password"
                id="password"
                :class="{ 'is-danger': $v.password.$error }"
                v-model.trim="$v.password.$model"
              />
              <span class="icon is-small is-left">
                <font-awesome-icon icon="lock"></font-awesome-icon>
              </span>
            </div>
          </div>
          <div class="buttons is-centered mt-3">
            <button
              id="btn_login"
              class="button is-danger has-text-weight-bold"
              :disabled="isLoading || $v.$anyError"
            >
              Entrar
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="container-bottom">
      <p>Todos los derechos reservados</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { required, minLength } from "vuelidate/lib/validators"
export default {
  data() {
    return {
      username: "",
      password: "",
      isLoading: false
    }
  },
  validations: {
    username: {
      required,
      minLength: minLength(4)
    },
    password: {
      required,
      minLength: minLength(4)
    }
  },
  methods: {
    async submitLogin() {
      this.isLoading = true
      if (!this.$v.$anyError) {
        try {
          await this.login({
            username: this.username,
            password: this.password
          })
          const redirectPath = this.$route.query.redirect || "/user"
          this.$router.push(redirectPath)
        } catch (error) {
          this.$notyf.error(error)
        }
        this.isLoading = false
      }
    },
    ...mapActions({
      login: "auth/login"
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/style.scss";
#login {
  display: inline-block;
  width: 100%;
  height: 100%;

  .container-top,
  .container-bottom {
    height: 50%;
    width: 100%;
  }

  .container-top {
    background-color: $primary;
  }

  .container-bottom {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    p {
      margin: 20px;
    }
  }

  .container-login {
    color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;

    .login {
      background-color: $secondary;
      width: 480px;
      height: 400px;
      border-radius: 15px;
      padding: 55px 30px;

      img {
        position: absolute;
        top: 0;
        right: 50%;
        transform: translate(50%, 0);
      }
    }
  }
}
</style>
